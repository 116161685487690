import React from "react";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import ServiceRequestModal from "../Support/ServiceRequestModal";
import { getValueByKey } from "../../../utils/authUtils";

const ServiceRequestModalComponent = ({
  modalView,
  setModalView,
  hraMemberId,
  vendorDetails,
  isLiked,
  likeVendorRequest,
  listAPi,
}) => {
  const memberData = {
    accountName: getValueByKey("accountName"),
    storeNameRegular: getValueByKey("storeNameRegular"),
    phoneNumber: getValueByKey("phoneNumber"),
    name:
      getValueByKey("firstName")?.replace(/"/g, "") +
      " " +
      getValueByKey("lastName")?.replace(/"/g, ""),
    email: getValueByKey("memberEmail")?.replace(/"/g, ""),
  };
  const handleModalDismiss = () => {
    setModalView(false);
  };

  const handleSubmitCall = () => {
    if (isLiked?.id) {
      likeVendorRequest({
        vendorId: vendorDetails.id,
        interestedVendorId: isLiked.id,
        isActive: !isLiked.isActive,
        hraMemberId: hraMemberId,
        listAPi: listAPi,
      });
    } else {
      likeVendorRequest({
        vendorId: vendorDetails.id,
        interestedVendorId: null,
        hraMemberId: hraMemberId,
        listAPi: listAPi,
      });
    }
  };
  return (
    <CustomisedModal
      modalView={modalView}
      customClass={"category-modal support-request-modal"}
      modalName={
        <div className="ach_modal_header d-flex justify-content-between w-100">
          Service Request
          <span>{hraMemberId}</span>
        </div>
      }
      onModalDismiss={handleModalDismiss}
    >
      <ServiceRequestModal
        initialValues={{
          subject: vendorDetails?.name
            ? `${memberData?.name}: ${hraMemberId} is interested in the ${vendorDetails.name} Program`
            : "",
          description: isLiked?.isActive
            ? ""
            : `Hi ${vendorDetails?.name}\n\n${memberData?.name} has expressed interest in participating in the current promotion. Please connect with this valued member and help them participate in the promotion. We appreciate your support.\n\nPlease keep us informed as things progress and let us know if we can help in any way.
`,
          issue: "Promotions",
          vendor: vendorDetails?.name ? vendorDetails.name : "",
          priority: "Medium",
        }}
        onSubmitCall={handleSubmitCall}
        defaultValues={true}
        memberData={memberData}
        onModalDismiss={handleModalDismiss}
      />
    </CustomisedModal>
  );
};

export default ServiceRequestModalComponent;
