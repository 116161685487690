import { Field, Formik, Form } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormGroup, Label, Row } from "reactstrap";
import { __t } from "../../../i18n/translator";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { connect } from "react-redux";
import {
  CallSvg,
  EmailSvg2,
  LocationSvg,
  ShopSvg,
  UserSvg2,
} from "../../../components/CommonSvg";
import { optionsIsuue, optionsPriority } from "../../Frontend/Support/common";
import { createSupportRequest, getSupportVendorRequest } from "../../../redux/admin/support/action";
import FileAttachment from "../SupportRequest/FileAttachment";


function SupportTicket(props) {
  const {
    getVenderListRequest,
    dispatchData,
    SupportTicketRequest,
    selectedStore,
    vendorList,
    onModalDismiss,
  } = props;
  const validator = useRef(new SimpleReactValidator());
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      const formData = new FormData();
      // Append other form fields to the FormData
      if (selectedStore?.ticketId) {
        formData.append("ticketId", selectedStore.ticketId);
      }
      formData.append("hraMemberId", selectedStore.hraMemberId);
      formData.append("issue", values.issue);
      formData.append("vendor", values.vendor);
      formData.append("priority", values.priority);
      formData.append("subject", values.subject);
      formData.append("description", values.description);
      formData.append("bcc", values.Bcc);
      formData.append("cc", values.cc);
      formData.append("vendorEmail", selectedStore.vendorEmail);
      // Append files to FormData
      files.forEach((file, index) => {
        formData.append(`attachments`, file, file.name);
      });
      SupportTicketRequest({ formData, dispatchData });
      onModalDismiss();
    } else {
      validator.current.showMessages();
    }
  };
  // const [selectedOption, setSelectedOption] = useState(null);
  // const [selectedVendor, setSelectedVendor] = useState(null);
  // const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedOption, setSelectedOption] = useState(
    selectedStore?.category
      ? { value: selectedStore.category, label: selectedStore.category }
      : null
  );
  const [selectedPriority, setSelectedPriority] = useState(
    selectedStore?.priority
      ? { value: selectedStore.priority, label: selectedStore.priority }
      : null
  );

  const [selectedVendor, setSelectedVendor] = useState(
    { value: selectedStore?.vendorId, label: selectedStore.vendorName }
      ? { value: selectedStore.vendorId, label: selectedStore.vendorName }
      : null
  );
  const [files, setFiles] = useState([]);

  useEffect(() => {
    getVenderListRequest();
  }, []);
  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.background,
      color: state.data.color, // You can customize the text color here
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color, // You can customize the text color here
    }),
  };
  const storeFull = selectedStore.hraMemberId ? selectedStore?.storeNameRegular?.includes("HRA")
    ? selectedStore?.storeNameRegular
    : `${selectedStore?.storeNameRegular} - ${selectedStore.hraMemberId}` : "";

  return (
    <>
      <Formik
        initialValues={{
          issue: selectedStore?.category ? selectedStore.category : "",
          vendor: selectedStore?.vendorName ? selectedStore.vendorName : "",
          priority: selectedStore?.priority ? selectedStore.priority : "",
          // subject: vendorDetails?.name ? ``: "",
          subject: `${selectedStore?.category ? selectedStore.category : "New Service Request"}: ${storeFull ? `${storeFull} & ` : ""}${selectedStore?.vendorName ? `${selectedStore.vendorName}` : ``} ` || "",
          description: selectedStore?.details ? selectedStore?.details : "",
          attachments: [],
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <>
            <Form className="form-horizontal form-login">
              <div className="category-head">
                {selectedStore?.storeNameRegular && <div className="support-request-modal-list">
                  <div className="support-request-modal-data">
                    <span className="support-icon-box">
                      <UserSvg2 />
                    </span>
                    <p>
                      {(selectedStore?.firstName || "-") +
                        " " +
                        (selectedStore?.lastName || "-")}
                    </p>
                  </div>
                  <div className="support-request-modal-data">
                    <span className="support-icon-box">
                      <ShopSvg />
                    </span>
                    <p>{selectedStore?.storeNameRegular || "-"}</p>
                  </div>
                  <div className="support-request-modal-data">
                    <span className="support-icon-box">
                      <CallSvg />
                    </span>
                    <p>{selectedStore?.phoneNumberMobileNumber || "-"}</p>
                  </div>
                  <div className="support-request-modal-data">
                    <span className="support-icon-box">
                      <EmailSvg2 />
                    </span>
                    <p>{selectedStore?.emailAddress || "-"}</p>
                  </div>
                  <div className="support-request-modal-data address">
                    <span className="support-icon-box">
                      <LocationSvg />
                    </span>
                    <p>
                      {(selectedStore?.mailingStreetAddress || "-") +
                        ", " +
                        (selectedStore?.city || "-") +
                        ", " +
                        (selectedStore?.state || "-") +
                        ", " +
                        (selectedStore?.mailingZip || "-")}
                    </p>
                  </div>
                </div>}
                {/* {sendMail ? (
                  
                ) : ( */}
                <Row>
                  <FormGroup className="col-4 mb-3">
                    <Select
                      value={selectedOption}
                      menuPlacement="auto"
                      onChange={(selected) => {
                        setSelectedOption(selected);
                        setFieldValue("issue", selected?.value);
                        setFieldValue("subject", `${selected.label}: ${storeFull}${values.vendor ? ` & ${values.vendor}` : ""} `
                        );
                      }}
                      options={optionsIsuue}
                      className="form-control selectbox"
                      // isDisabled={defaultValues}
                      id="issue"
                      name="issue"
                    />
                    <Label className="form-label" htmlFor="category">
                      Category
                      <span className="text-danger required">*</span>
                    </Label>{" "}
                    {validator.current.message(
                      __t("validations.support.issue"),
                      values.issue,
                      "required",
                      {
                        messages: {
                          required: __t("validations.support.issue"),
                        },
                      }
                    )}
                  </FormGroup>
                  <FormGroup className="col-4 mb-3">
                    <Select
                      value={selectedVendor}
                      menuPlacement="auto"
                      onChange={(selected) => {
                        setSelectedVendor(selected);
                        setFieldValue("vendor", selected?.value);
                        setFieldValue("subject", `${values.issue ? values.issue : "New Service Request"}: ${storeFull} & ${selected.label}`
                        );
                      }}
                      options={vendorList}
                      className="form-control selectbox"
                      id="vendor"
                      name="vendor"
                    />
                    <Label className="form-label" htmlFor="Vendor">
                      Vendor
                      <span className="text-danger required">*</span>
                    </Label>{" "}
                    {validator.current.message(
                      __t("validations.support.vendor"),
                      values.vendor,
                      "required",
                      {
                        messages: {
                          required: __t("validations.support.vendor"),
                        },
                      }
                    )}
                  </FormGroup>
                  <FormGroup className="col-4 mb-3">
                    <Select
                      value={selectedPriority}
                      menuPlacement="auto"
                      onChange={(selected) => {
                        setSelectedPriority(selected);
                        setFieldValue("priority", selected?.value);
                      }}
                      options={optionsPriority}
                      //defaultValues ? "field_disabled form-control selectbox" :
                      className={"form-control selectbox"}
                      // isDisabled={defaultValues}
                      styles={colourStyles}
                      id="priority"
                      name="priority"
                    />
                    <Label className="form-label" htmlFor="priority">
                      Priority
                      <span className="text-danger required">*</span>
                    </Label>{" "}
                    {validator.current.message(
                      __t("validations.support.priority"),
                      values.priority,
                      "required",
                      {
                        messages: {
                          required: __t("validations.support.priority"),
                        },
                      }
                    )}
                  </FormGroup>
                  <FormGroup className="col-12 mb-3">
                    <Field
                      type="subject"
                      id="subject"
                      name="subject"
                      className="form-control"
                      placeholder="Full Name"
                    />
                    <Label className="form-label" htmlFor="fullname">
                      Subject
                      <span className="text-danger required">*</span>
                    </Label>
                    {validator.current.message(
                      __t("validations.support.subject"),
                      values.subject,
                      "required",
                      {
                        messages: {
                          required: __t("validations.support.subject"),
                        },
                      }
                    )}
                  </FormGroup>
                  <FormGroup className="col-12 mb-3">
                    <Field
                      as="textarea"
                      rows={3}
                      name="description"
                      placeholder="description.."
                      className="form-control"
                      maxLength="1050"
                    />
                    <Label className="form-label" htmlFor="description">
                      Description
                    </Label>{" "}
                    {validator.current.message(
                      __t("validations.support.description"),
                      values.description,
                      "required",
                      {
                        messages: {
                          required: __t("validations.support.description"),
                        },
                      }
                    )}
                  </FormGroup>
                  <FileAttachment files={files} setFiles={setFiles} />
                </Row>
                <Row className="include-cc">
                  <Label className="mb-2">Include CC and BCC in Email?</Label>
                  <FormGroup className="col-6 mb-3">
                    <Field
                      type="text"
                      id="cc"
                      name="cc"
                      className="form-control"
                      placeholder="cc"
                    />
                    <Label className="form-label" htmlFor="fullname">
                      Cc
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-6 mb-3">
                    <Field
                      type="text"
                      id="Bcc"
                      name="Bcc"
                      className="form-control"
                      placeholder="Bcc"
                    />
                    <Label className="form-label" htmlFor="fullname">
                      Bcc
                    </Label>
                  </FormGroup>
                </Row>
                {/* )} */}
              </div>
              <div className="btn-box">
                <Button
                  color="primary"
                  className=""
                  title="Submit"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
const mapStateToProps = ({ support }) => {
  let vendorList =
    support.suppoerVendor?.list?.length > 0
      ? support.suppoerVendor.list.map((data) => {
        return {
          label: data.name,
          value: data.name,
        };
      })
      : [];

  return { vendorList };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getVenderListRequest: (data) => dispatch(getSupportVendorRequest(data)),
    SupportTicketRequest: (data) => dispatch(createSupportRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupportTicket);
