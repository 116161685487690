
export const addUserInitialState = (values) => {
    let value = values === undefined ? "" : values;

    return {
        email: value?.email,
        phoneNumber: value?.phoneNumber,
        roleId: value?.roleId,
        fullName: value?.fullName,
        userId: value?.id,
        vendorAccess: value?.vendorAccess,
        addVendorContacts: value?.moduleAccess?.addVendorContacts,
        editVendorContacts: value?.moduleAccess?.editVendorContacts,
        achManagement: value?.moduleAccess?.achManagement,
        achManagementExcel: value?.moduleAccess?.achManagementExcel
    };
};
