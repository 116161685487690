import { getFileNameFromURL } from "../../../utils/generalUtils";

export const achInitialState = (values) => {
  const ImageData = values?.achManagement?.map((d) => {
    return {
      fileNames: getFileNameFromURL(d.currentDocument),
      imageUrl: d.currentDocument,
    };
  });
  return {
    routingNumber: values?.routingNumber ? values.routingNumber : "",
    accountNumber: values?.accountNumber ? values.accountNumber : "",
    chequeNumber: values?.chequeNumber ? values.chequeNumber : "",
    imageFile: ImageData ? ImageData : "",
    hraMemberId: values?.hraMemberId ? values.hraMemberId : "",
    bankName: values?.bankName ? values.bankName : "",
    bankCity: values?.bankCity ? values.bankCity : "",
    bankAddress: values?.bankAddress ? values.bankAddress : "",
    bankState: values?.bankState ? values.bankState : "",
    bankZip: values?.bankZip ? values.bankZip : "",
    printName: values?.printName ? values.printName : "",
    sign: values?.sign ? values.sign : "",
    accountType: values?.accountType || "",

  };
};
export const editAchInitialState = (values) => {
  const ImageData = values?.achManagement?.map((d) => {
    return {
      fileNames: getFileNameFromURL(d.previousDocument),
      imageUrl: d.previousDocument,
    };
  });

  return {
    routingNumber: values?.previousRoutingNumber
      ? values.previousRoutingNumber
      : "",
    accountNumber: values?.previousAccountNumber
      ? values.previousAccountNumber
      : "",
    chequeNumber: values?.previousChequeNumber
      ? values.previousChequeNumber
      : "",
    imageFile: ImageData ? ImageData : "",
    hraMemberId: values?.hraMemberId ? values.hraMemberId : "",
    bankName: values?.previousBankName ? values.previousBankName : "",
    city: values?.previousCity ? values.previousCity : "",
    firstName: values?.firstName ? values.firstName : "",
    lastName: values?.lastName ? values.lastName : "",
    bankCity: values?.previousBankCity ? values.previousBankCity : "",
    bankState: values?.previousBankState ? values.previousBankState : "",
    bankZip: values?.previousBankZip ? values.previousBankZip : "",
    bankAddress: values?.previousBankAddress ? values.previousBankAddress : "",
    printName: values?.previousPrintName ? values.previousPrintName : "",
    sign: values?.previousSign ? values.previousSign : "",
    accountType: values?.previousAccountType ? values.previousAccountType : "",
  };
};
