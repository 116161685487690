import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import Signature from "signature_pad";
import { SignatureClearSvg } from "../../../components/CommonSvg";

function SignaturePad({ signImage, setFieldValue }) {
  const [signaturePad, setSignaturePad] = useState(null);
  const [localSignature, setLocalSignature] = useState(null);
  const canvasRef = useRef(null);
  const [isEditMode, setIsEditMode] = useState(signImage);
  const readyPad = () => {
    const wrapper = document.getElementById("signature-pad");
    const canvas = canvasRef.current;

    if (!canvas || !wrapper) {
      console.error("Canvas or wrapper not found");
      return;
    }

    const rect = wrapper.getBoundingClientRect();
    canvas.width = rect.width;
    canvas.height = rect.height;

    const ratio = window.devicePixelRatio || 1;
    canvas.width = rect.width * ratio;
    canvas.height = rect.height * ratio;
    canvas.getContext("2d").scale(ratio, ratio);

    const tempSignaturePad = new Signature(canvas, {
      backgroundColor: "rgb(255, 255, 255)",
      penColor: "rgb(0, 0, 0)",
      dotSize: 1.5,
      minWidth: 1,
      maxWidth: 2.5,
    });

    tempSignaturePad.addEventListener("endStroke", () => {
      console.log("Stroke ended");
      if (!tempSignaturePad.isEmpty()) {
        const signatureData = tempSignaturePad.toDataURL();
        setFieldValue("sign", signatureData);
        setLocalSignature(signatureData);
      } else {
        console.log("Signature pad is empty");
      }
      tempSignaturePad.on();
    });

    tempSignaturePad.addEventListener("beginStroke", () => {
      console.log("Stroke began");
      tempSignaturePad.penColor = "rgb(0, 0, 0)";
    });

    tempSignaturePad.on();
    setSignaturePad(tempSignaturePad);
  };

  const handleClear = () => {
    console.log("Clearing signature");
    setFieldValue("sign", null);
    setLocalSignature(null);
    setIsEditMode(null)
    if (signaturePad) {
      signaturePad.clear();
      signaturePad.on();
    }
  };

  useEffect(() => {
    console.log("Initializing signature pad");
    readyPad();
    return () => {
      if (signaturePad) {
        signaturePad.off();
        console.log("Cleaning up signature pad");
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (signaturePad) {
        const canvas = canvasRef.current;
        const wrapper = document.getElementById("signature-pad");
        const data = signaturePad.toData();

        const rect = wrapper.getBoundingClientRect();
        const ratio = window.devicePixelRatio || 1;
        canvas.width = rect.width * ratio;
        canvas.height = rect.height * ratio;
        canvas.getContext("2d").scale(ratio, ratio);

        signaturePad.fromData(data);
        signaturePad.on();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [signaturePad]);

  const displaySignature = signImage || localSignature;

  return (
    <Card id="signature-pad" className="mb-3" style={{ overflow: "hidden" }}>
      <CardHeader className="py-2">
        <h3 className="m-0">Your Signature</h3>
        <Button
          onClick={handleClear}
          size="sm"
          color="primary"
          className="gap-0"
        >
          <SignatureClearSvg /> Clear
        </Button>
      </CardHeader>
      <CardBody style={{ padding: 0 }}>
        <div
          className="saved-signature"
          style={{ position: "relative", height: "200px" }}
        >
          <canvas
            ref={canvasRef}
            className="signature-canvas"
            style={{
              width: "100%",
              height: "100%",
              border: "1px solid #ccc",
              display: isEditMode ? "none" : "block",
            }}
          />
          {displaySignature && (
            <>
              <img
                className="signature-image"
                alt="saved-signature"
                src={displaySignature}
                // style={{ display: "none" }}
              />
              {/* <Button
                                onClick={handleClear}
                                color=""
                                style={{ position: "absolute", top: "10px", right: "10px" }}
                            >
                                <SignatureClearSvg />
                            </Button> */}
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default SignaturePad;
